import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import CallToAction from "../../components/CallToAction/CallToAction";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Layout from "../../components/Layout/Layout";
import PracticeAreasGrid from "../../components/Grid/PracticeAreas/PracticeAreasGrid";
import SingleImage from "../../components/SingleMedia/SingleImage";


const PracticeAreasPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/Services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2400, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      title="Our Areas of Practice"
      description="KND's lawyers predominately litigate class actions and 
      other large or complex lawsuits in the following five main practice 
      areas: Securities Law, Privacy Law, Consumer Protection & Product 
      Liability Law, Employment Law, Price Fixing & Competition Law."
    >
      <Header
        colorScheme="light"
      />

      <Hero
        title="Areas of Practice"
        subtitle="KND's lawyers predominately litigate class actions and 
        other large or complex lawsuits in the following five main practice 
        areas: Securities Law, Privacy Law, Consumer Protection & Product 
        Liability Law, Employment Law, Price Fixing & Competition Law."
        contactInfo={ false }
        colorScheme="light"
      />

      <SingleImage
        image={ data.file.childImageSharp.fluid }
        imageAlignment="left"
        fullWidth={ false }
        altText="Strategic chess playing related to law"
        colorScheme="light"
      />

      <PracticeAreasGrid
        heading="Our areas of expertise"
        buttonText="Learn more"
        buttonPosition="left"
      />

      <CallToAction />

      <Footer />
    </Layout>
  );
}

export default PracticeAreasPage;